import React from "react";
import { Link } from "gatsby";
import HowToLayout from "../../../components/how-to-layout";

const Schedule = () => (
  <HowToLayout>
    <h2>Schedule</h2>
    <section className="documentation-section">
      <p>
        The schedule is very straightforward. 4BAGR creates what's referred to
        as a 'Round Robin' league. Each team will play all other teams one time
        before repeating any opponents (where the schedule will then restart to
        the same opponents played week 1). The schedule is generated based off
        of your criteria set in the league settings.
      </p>
      <div className="youtube-vid-wrap">
        <iframe
          width="100%"
          height="100%"
          style={{ position: "absolute" }}
          src="https://www.youtube.com/embed/1erBbbNyOAw"
          title="Generate League Schedule Walkthrough video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </section>
    <section className="documentation-section">
      <h3 id="schedule-generation">Generating the Schedule</h3>
      <p>
        Double check your settings once again to make sure you have everything
        the way you want it. It's recommended to have an even amount of teams,
        if not you'll need to create a 'Bye' team and handle it appropriately on
        the schedule. Most organizers will tell their teams to skip inputting
        scores against the 'Bye'.
      </p>
      <ol>
        <li>Within 4BAGR: Navigate to your league</li>
        <li>
          You're now on the 'Schedule' Screen and should see a 'Generate
          Schedule' Button. Click that.
        </li>
        <li>
          Confirm you have all of your teams in place. If you have an odd amount
          of teams, you will need to create a 'Bye' team so the schedule is not
          created unevenly.{" "}
        </li>
        <li>Click 'Generate'</li>
        <li>
          You will get a 'Success' message and now can navigate back to your
          schedule screen
        </li>
      </ol>
      <p>
        Now that the schedule has been generated, give it a once over to make
        sure everything matches your criteria properly.
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="schedule-deletion">Deleting the Schedule</h3>
      <p>
        If you've noticed an issue with the schedule or need to add/remove
        teams, you'll need to delete the schedule and re-generate it. NOTE: once
        you delete the schedule, this whipes the scores/standings. Only do this
        if you're not yet started with the league or you will need to re-enter
        scores or replay those league nights.
      </p>
      <ol>
        <li>Within 4BAGR: Navigate to your league settings screen</li>
        <li>
          Scroll about halfway down and you'll see the 'Delete Schedule' button
        </li>
        <li>Click that and confirm that you're aware of the consequences</li>
        <li>You can now add/remove teams or update your schedule settings.</li>
      </ol>
    </section>
    <section className="documentation-section">
      <h3 id="editing-a-match">Editing a Match</h3>
      <p>
        There's several occasions that you might need to update a specific
        match:
      </p>
      <ul>
        <li>
          A team cannot make league night and you need to move the match to
          another date/time
        </li>
        <li>Update the progress status of a match</li>
        <li>A team forgot to input their scores or messed up the input</li>
        <li>Changing the assigned court</li>
      </ul>
      <h5 style={{ marginBottom: 10 }}>Updating a Match</h5>
      <ol>
        <li>Within 4BAGR: Navigate to your league schedule screen</li>
        <li>Press on the match that you'd like to update</li>
        <li>
          If you'd like to update the progress, press the 'match status' button
          until you get the desired status and then press 'update'
        </li>
        <li>
          To change the week, court assignment, or time - Press 'Edit
          date/time/court' button at the top
        </li>
        <li>
          You will now see the options that you can update: Week, Court, and
          time
        </li>
        <li>Make changes and press the 'update' button</li>
      </ol>
      <p>
        All updates are made in real time, meaning you will not need to refresh
        the app or screen to see the change made. The same goes for anyone
        currently viewing the schedule/standings/roster.
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="postpone-schedule">Postpone the schedule</h3>
      <p>
        Stuff happens. We get that. Sometimes your league gets rained out or
        holidays happen over the course of the league and you'll need to
        postpone for a week or 2. No problem.
      </p>
      <ol>
        <li>Within 4BAGR: Navigate to your league schedule screen</li>
        <li>
          Navigate to the desired week that you'd like to postpone to a later
          date
        </li>
        <li>Press the 'Postpone' button at the top of the screen</li>
        <li>Select the amount of weeks that you'd like to postpone</li>
      </ol>
      <p>
        Upon league postponement, you'll notice that the week you selected and
        all following weeks will have been postponed the amount of weeks that
        you selected. Your league will continue to stay on schedule even though
        you're skipping some weeks and picking up later.
      </p>
      <div className="youtube-vid-wrap">
        <iframe
          width="100%"
          height="100%"
          style={{ position: "absolute" }}
          src="https://www.youtube.com/embed/oc-NpH--iaM"
          title="Postpone League Schedule Walkthrough video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </section>
    <section className="documentation-section">
      <h3 id="assign-courts">Assigning Courts</h3>
      <p>
        We offer an 'Auto assign courts' feature that will assign the courts to
        every match in the entire schedule based on the # of courts you've
        designated in the settings.
      </p>
      <ol>
        <li>Within 4BAGR: Navigate to your league schedule screen</li>
        <li>Press 'Assign Courts' at the top of the screen</li>
        <li>
          Confirm that you are okay with overwriting any currently assigned
          courts and press 'Yes'
        </li>
      </ol>
      <p>
        You'll notice all matches in the schedule have been updated with a court
        assignment. These can be updated at a later time by selecting the match
        and editing the court assignment manually.
      </p>
    </section>
  </HowToLayout>
);

export default Schedule;
